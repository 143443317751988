// THIS CODE RUNS BEFORE THE BABEL POLYFILL - To keep ie11 support please DO NOT USE fetch, promises or other things that ie11 does not support
// As this code needs to run as quickly as possible on page load it should remain small and import free
// If you are making changes here YOU MUST test in ie11
const embeddedReactApps = require('../embeddedReactApps').default;

const hash = window.location.hash.replace('#', '');

function loadEmber() {
  // We expect this to be defined in the template
  window.emberScripts.forEach(script => insertScriptTag(script, function() {}));
}

function loadApp(appName, cb) {
  loadAssetMap(appName, function(err, assetMap) {
    if (err) {
      return cb(err);
    }
    loadScripts(assetMap.bootEntrypointAssets.scripts, function(err) {
      if (err) {
        return cb(err);
      }
      cb();
    });
  });
}

function loadAssetMap(appName, cb) {
  const req = new XMLHttpRequest();
  req.open('GET', `/app/${appName}/assetMap.json`, true);
  req.addEventListener('load', function() {
    cb(null, JSON.parse(req.responseText));
  });
  req.addEventListener('error', () => {
    cb(new Error(`Failed to load ${appName} assetMap`));
  });
  req.send();
}

function loadScripts(scripts, cb) {
  let completedCount = 0;
  scripts.forEach(function(script) {
    insertScriptTag(script, function(err) {
      if (err) {
        return cb(err);
      }

      completedCount += 1;
      if (completedCount === scripts.length) {
        cb();
      }
    });
  });
}

function insertScriptTag(scriptSrc, cb) {
  if (!document.querySelector(`script[src="${scriptSrc}"]`)) {
    const script = document.createElement('script');
    script.src = scriptSrc;
    script.async = false;
    script.setAttribute('type', 'application/javascript');
    script.addEventListener('load', function() {
      cb();
    });

    script.addEventListener('error', function(e) {
      cb(e.error);
    });

    document.head.appendChild(script);
  }
}

let bootLoading = true;
let appLoading = false;

// Array.prototype.find not available in ie 11
let reactAppToLoad;
embeddedReactApps.forEach(function(app) {
  if (app.path === hash) {
    reactAppToLoad = app;
  }
});

if (window.deferEmberScriptsSwitch) {
  if (reactAppToLoad) {
    window.addEventListener('DOMContentLoaded', function() {
      bootLoading = false;
      if (!appLoading) {
        loadEmber();
      }
    });

    // We're already loading boot
    if (reactAppToLoad.app !== 'boot') {
      appLoading = true;
      loadApp(reactAppToLoad.app, function(err) {
        if (err) {
          console.error(`Failed to load app ${reactAppToLoad.app}`, err);
        }

        if (!bootLoading) {
          loadEmber();
        }
      });
    }
  } else {
    loadEmber();
  }
}
