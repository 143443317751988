// Map of paths to the app that we want to embed

type ReactApp = { app: string; path: string; switch?: string; exact: boolean; productTitleDefault?: string };

const embeddedReactApps: ReactApp[] = [
  { path: '', app: 'boot', exact: true },
  { path: '/', app: 'boot', exact: true },
  { path: '/employeedirectory', app: 'people', exact: true },
  { path: '/employeeorgchart', app: 'people', exact: true },

  // Payroll taxes pages
  { path: '/payroll/taxes', app: 'payroll', exact: false },
  { path: '/payroll/onboarding-taxes', app: 'payroll', exact: false },

  // Payroll reports page
  { path: '/payroll/reports-beta', app: 'payroll', exact: false },

  // Employee Eligibility
  { path: '/employeei9/:employeeEligibilityId/:action', app: 'hr-employee', exact: true },
  { path: '/employeei9/:employeeEligibilityId', app: 'hr-employee', exact: true },
  { path: '/reverifyi9/:employeeEligibilityId/:action', app: 'hr-employee', exact: true },
  { path: '/reverifyi9/:employeeEligibilityId', app: 'hr-employee', exact: true },

  // Employee Profile
  { path: '/employee-colleague/:employeeId', app: 'hr-employee', exact: true },
  {
    path: '/employee-profile/:employeeId/account-info',
    app: 'hr-employee',
    switch: 'employee_profile_react_account_info',
    exact: true,
  },
  {
    path: '/employee-profile/:employeeId/custom-fields',
    app: 'hr-employee',
    switch: 'employee_profile_react_custom_fields',
    exact: true,
  },
  {
    path: '/employee-profile/:employeeId/eligibility',
    app: 'hr-employee',
    exact: true,
  },
  {
    path: '/employee-profile/:employeeId/employment-info',
    app: 'hr-employee',
    switch: 'employee_profile_react_employment_info',
    exact: true,
  },
  {
    path: '/employee-profile/:employeeId/groups',
    app: 'hr-employee',
    switch: 'employee_profile_react_groups',
    exact: true,
  },
  {
    path: '/employee-profile/:employeeId/integrations',
    app: 'hr-employee',
    switch: 'employee_profile_react_integrations',
    exact: true,
  },
  {
    path: '/employee-profile/:employeeId/payroll-settings',
    app: 'hr-employee',
    switch: 'employee_profile_react_payroll_settings',
    exact: true,
  },
  {
    path: '/employee-profile/:employeeId/personal-info',
    app: 'hr-employee',
    exact: true,
  },
  {
    path: '/employee-profile/:employeeId/projects',
    app: 'hr-employee',
    exact: true,
  },
  {
    path: '/employee-profile/:employeeId/tax-info',
    app: 'hr-employee',
    switch: 'employee_profile_react_tax_info',
    exact: true,
  },
  {
    path: '/employee-profile/:employeeId/work-groups',
    app: 'hr-employee',
    switch: 'employee_profile_react_work_groups',
    exact: true,
  },

  // benconnect - court order dependents
  {
    path: '/court-order-dependents/:employeeId',
    app: 'benconnect',
    exact: false,
    productTitleDefault: 'Court Order Dependents',
  },
];

export default embeddedReactApps;
